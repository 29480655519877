import { checkBeforeEnter } from '@/auth/utils';

export default [
    {
        path: '/desempenos',
        name: 'performances-list',
        component: () => import('@/views/modules/performances/performances-list/PerformancesList.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Desempeños',
            breadcrumb: [{
                text: 'Lista de desempeños',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/desempenos/evaluar/:id',
        name: 'performances-evaluate',
        component: () => import ('@/views/modules/performances/performances-evaluate/PerformancesEvaluate.vue'),
        beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin'], to, from, next) },
        meta: {
            resource: 'ACL',
            pageTitle: 'Desempeños',
            breadcrumb: [{
				text: 'Lista de desempeños',
				to: { name: 'performances-list' },
			}, {
					text: 'Evaluar desempeño',
                active: true
            }],
            requiresAuth: true
        }
    }
]